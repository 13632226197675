import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import { flow, get, groupBy } from 'lodash/fp'
import map from 'lodash/map'
import Fade from 'transitions/Fade'
import styled, { ThemeContext } from 'styled-components'

import { LinkDecorated } from 'components/Typography'

import {
  Container,
  Row,
  Col
} from 'components/Grid'

import Head from 'components/Head'
import PageHeader from 'components/PageHeader'

const LinkGroups = styled.div`
  position: relative;
  columns: 1;

  ${p => p.theme.media.minWidth('tablet')} {
    columns: 2;
  }

  ${p => p.theme.media.minWidth('desktop')} {
    columns: 3;
  }
`

const LinkGroup = styled.div`
  position: relative;
  break-inside: avoid-column;
  margin: 0 0 0 0;
`

const LinkGroupLinks = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const LinkGroupLink = styled.li`
  ${p => p.theme.typography.tMedium};
  margin: 0 0 .75em 0;
`

const AlphabeticalLinkListPage = ( props ) => {
  const page = flow(
    get('data.contentful.nodes[0]')
  )(props)

  const seoDescription = get('seoDescription.seoDescription')(page)
  const seoImage = get('seoImage.default.images.fallback.src')(page)

  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.global.setHeaderPadding(!page.displayPageHeader)
  }, [page.displayPageHeader, theme.global])

  let list = flow(
    groupBy(l => l.title[0].toUpperCase())
  )(page.links)
  
  return (
    <Fade>
      <Head
          title={page.title}
          description={seoDescription}
          image={seoImage}
      />
      {page.displayPageHeader && (
         <PageHeader
             title={page.title}
             description={page.description}          
             layout={page.pageHeaderLayout} />
       )}
      <Container>
        <Row>
          <Col mb={22} mbPush={1} dk={20} dkPush={2}>
            <LinkGroups>
              {map(list, (links, i) => (
                 <LinkGroup key={i}>
                   <LinkGroupLinks>
                     {map(links, (link, i) => (
                        <LinkGroupLink key={i}>
                          <LinkDecorated to={link.link}>{link.title}</LinkDecorated>
                        </LinkGroupLink>
                      ))}
                   </LinkGroupLinks>
                 </LinkGroup>
              ))}
            </LinkGroups>
          </Col>
        </Row>
      </Container>
    </Fade>
  )
}

export const query = graphql`
  query AlphabeticalLinkListPageQuery(
    $pageId: String!
   ) {
     contentful: allContentfulPageAlphabeticalLinkList(
       limit: 1, 
       filter: { 
         id: { 
           eq: $pageId
         }
       }
     ) {
       nodes {
         ...HeadPageAlphabeticalLinkList
         id
         title
         description {
           raw
         }         
         displayPageHeader
         pageHeaderLayout
         links {
           id
           title
           link
         }
       }
     }         
   }   
 `

export default AlphabeticalLinkListPage
